/* eslint-disable vue/no-reserved-component-names */
/* eslint-disable vue/multi-word-component-names */
import "g/../css/app.css";
import trans from "g/Scripts/Translations.js";
import axios from "axios";
import { getPage } from "./base.js";
import { createApp, h } from "vue";
import { createInertiaApp, Link } from "@inertiajs/vue3";

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

let vueApp;
createInertiaApp({
  progress: {
    delay: 250,
    color: import.meta.env.VITE_LOADING_BAR_COLOR ?? "#283747",
    includeCSS: true,
    showSpinner: false,
  },
  resolve: async (name) => {
    return await getPage(name);
  },
  setup({ el, App, props, plugin }) {
    delete el.dataset.page;
    import("./sentry").then((sentry) => {
      vueApp = createApp({ render: () => h(App, props) });
      vueApp.component("Link", Link);
      vueApp.use(trans, props.initialPage.props ?? {});
      vueApp.use(plugin);
      sentry.default(vueApp);
      vueApp.mount(el); // last
    });
    return vueApp;
  },
});

if (import.meta.env.SSR === false) {
  window.cookieConsent = localStorage.getItem("cookieConsent");
}

try {
  if (typeof window !== "undefined") {
    window.cookieConsent = localStorage.getItem("cookieConsent");
  }
} catch (error) {
  console.log("localstorage error");
}
