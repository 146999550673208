import { usePage } from "@inertiajs/vue3";

const $page = usePage();

var translationCache = [];

export default {
  install: (app, options) => {
    if (options && options.translations) {
      translationCache = options.translations;
    }

    const trans = (key, replace = {}) => {
      // var startTime = performance.now();

      const locale = $page.props.locale;

      const translations = translationCache[locale] ?? [];

      if (!key.includes(".")) {
        key = "messages." + key;
      }

      var translation = translations[key];

      Object.keys(replace).forEach(function (key) {
        if (translation) {
          translation = translation.replace(":" + key, replace[key]);
        }
      });

      // var endTime = performance.now();
      // console.log(`Translating took ${endTime - startTime} milliseconds`);

      return translation ?? key;
    };

    app.config.globalProperties.trans = trans;
    app.provide("trans", trans);
  },
};
