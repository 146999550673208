// HOX this file is shared between app.js && ssr.js

let layout;
let error;

export const getPage = async (name) => {
  const pages = import.meta.glob(["/resources/Custom/js/Pages/**/*.vue", "/resources/Global/js/Pages/**/*.vue"], { eager: false });
  const pageToResolve = pages?.[`/resources/Custom/js/Pages/${name}.vue`] || pages?.[`/resources/Global/js/Pages/${name}.vue`];
  var page = null;
  if (pageToResolve) {
    page = await pageToResolve();
    page.default.layout = page?.default?.layout || layout || (await import("@/Shared/Layout.vue").then((component) => (layout = component?.default)));
  }
  if (!page) {
    page = error || (await import("g/Components/Error/App.vue").then((component) => (error = component?.default || null)));
  }
  return page;
};
